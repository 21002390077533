import * as React from "react"
import H1 from "../utils/H1";
import Layout from "../components/layout"
import Seo from "../components/seo"
import Paragraph from "../utils/Paragraph"

import NewsTile from "../components/common/NewsTile"
import { StaticQuery, graphql } from "gatsby"

const News = (props) => (
    <StaticQuery
        query={graphql`
      query {
          allWpPortfolio {
              nodes {
                title
                portfolio {
                  description
                  link
                }
                featuredImage {
                  node {
                    mediaItemUrl
                  }
                }
                excerpt
              }
          }
        }
      `}

        render={data => (
            <Layout>
                <Seo title="Portfolio" />
                <div className="h-400px bg-stories bg-center bg-fixed bg-no-repeat bg-cover relative">
                    <div className="overlay absolute w-full h-full top-0 left-0 opacity-80 ">

                    </div>
                    <div className="absolute w-full h-full flex flex-col justify-center items-center">
                        <H1 className="text-white bg-none">News</H1>
                        <Paragraph className="text-white text-center lg:w-7/12 w-10/12 mt-8">Flying Point Industries has invested in a variety of successful companies. Explore their latest achievements and relavent news stories that have been recently published on blog sites and the like.</Paragraph>
                    </div>
                </div>
                <div className="lg:px-10% px-5% pb-20 bg-white w-full flex flex-col justify-center items-center">
                    {/* <h1 className="text-center lg:text-left text-blue bg-none text-4xl font-bold">A Selection of Our Portfolio Companies</h1> */}
                    <div className="grid grid-cols-1 auto-rows-auto gap-y-12 md:mt-20 mt-12 w-full">
                        {data.allWpPortfolio.nodes.map((row, index) => (
                            <NewsTile portfolio={true} data={row} key={index}></NewsTile>
                        ))}
                    </div>
                </div>
            </Layout>
        )}
    />

)
export default News

